@import '../../static/styles/variables';

footer {
  background-color: $main-color;
  min-height: $footer-height;
  border-top: $border-lg solid $footer-border;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;

  .footer-line {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;

    .footer-item {
      display: flex;
      flex-direction: row;
      align-items: center;

      .footer-icon-circle {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: $border-sm solid $gallery;
        z-index: 2;
        background-color: $white;
        display: flex;
        align-items: center;
        justify-content: center;
      }
      .footer-icon {
        color: $black;
        font-size: $font-size-md;
        text-align: center;
      }

      .footer-title {
        margin-left: 6px;
        margin-top: 1px;
        font-size: $font-size-sm;
        width: 150px;
        font-weight: $bold;

        .footer-anchor {
          text-decoration: none;
          color: $tundora;
        }
      }
    }
  }
}
