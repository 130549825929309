@import "../../static/styles/variables";
.named-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .name {
    text-transform: uppercase;
    color: $black;
    font-size: 30px;
    z-index: 10;
  }
  .title {
    color: #4f81bd;
    font-size: 16px;
    font-weight: $bold;
    z-index: 10;
  }
  .circle {
    border: 2px solid #fff;
    border-radius: 50%;
    height: 112px;
    width: 112px;
    position: absolute;
    z-index: 0;
    box-shadow: 0 0 1px #888;
  }
}