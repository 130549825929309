@import '../../static/styles/variables';
.speaking-events {
  margin-left: 18px;
  margin-bottom: 10px;
  .event {
    font-family: 'Roboto', sans-serif;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 8px;
    display: flex;
    align-items: center;
    .con-text {
      display: flex;
      flex-direction: column;
      .con-name {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
      }
      .con-sub {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        .con-date {
          text-transform: uppercase;
          font-size: 11px;
          font-weight: 300;
          color: $scorpion;
          &:after {
            content: "\2022";
            color: #5885d4;
            margin: 0px 8px;
          }
        }
        .con-location {
          color: $scorpion;
          font-size: 11px;
        }
      }
      .con-website {
        font-size: 10px;
        color: #333;
        font-weight: 300;
        text-decoration: none;
      }
    }
  }
}

ul.speaking-events {
  margin-block-start: 0em;
  font-family: $third-color;
  font-size: 13px;
  line-height: 1.25em;
  padding: 0;
  li {
    list-style: none;
    position: relative;
    padding: 0 0 0 22px;
  }
}

ul.circle-checkmark {
  li {
    &::before {
      content: "";
      position: absolute;
      left: 0px;
      top: 2px;
      border: solid 8px $third-color;
      border-radius: 8px;
      -moz-border-radius: 8px;
      -webkit-border-radius: 8px;
    }
    &::after {
      content: "";
      position: absolute;
      left: 5px;
      top: 5px;
      width: 3px;
      height: 6px;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      -webkit-transform: rotate(45deg);
      -moz-transform: rotate(45deg);
      -o-transform: rotate(45deg);
      transform: rotate(45deg);
    }
  }
}