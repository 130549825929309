@import '../../static/styles/variables';

/* possible 3380FF */

.first-shape {
  width: 5px;
  background: $third-color;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  margin-left: -5px;
  margin-right: -1px;
  height: 8px;
}

.last-shape {
  width: 7px;
  background: $third-color;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  margin-left: -2px;
  height: 8px;
}

.parallelogram {
  width: 30px;
  height: 8px;
  border-right: 3px solid $sidebar-bkg;
  color: rgba(0, 0, 0, 1);
  -o-text-overflow: clip;
  text-overflow: clip;
  background: $third-color;
  -webkit-transform: skewX(15deg);
  transform: skewX(15deg);
}

.parallelogram:nth-child(2) {
  width: 26px;
}

.parallelogram:nth-child(6) {
  border-right: none;
  width: 26px;
}

.wording {
  -webkit-transform: rotate(33deg);
  -moz-transform: rotate(33deg);
  -o-transform: rotate(33deg);
  width: 77px;
  display: inline-block;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  letter-spacing: 0.05em;
}

.measurement {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 12px;
  letter-spacing: 0.05em;
  margin-top: 20px;
  display: block;
  margin-left: 31px;
}

.measurement:first-child {
  margin-top: 0px;
}

.border:first-child {
  margin: 30px;
}

.border:not(:first-child) {
  margin-left: 30px;
}

.border {
  display: flex;
  width: 157px;
  border: 1px solid #333;
  padding: 1px 0px 1px 7px;
  border-radius: 10px;
  min-height: 12px;
}

.skills-container {
  margin-bottom: 12px;
}

.skills-group {
  margin-bottom: 25px;
}

.first-word {
  margin-left: 10px;
}

.words {
  margin-left: -46px;
  margin-top: 34px;
}

.word-box {
  margin-left: 30px;
  margin-top: -13px;
}

.skills-box {
  padding: 30px 0px;
}

.last-word {
  position: absolute;
}