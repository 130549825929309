/*
    Colors named using http://chir.ag/projects/name-that-color/
*/


/******************************
    Monochrome, Greys
*******************************/

$gallery: #eee;
$alto: #ddd;
$silver: #ccc;
$mine-shaft: #292828;
$emperor: #555555;
$alabaster: #fcfcfc;

/* could be f9f9f9 */

$tundora: #444;
$dusty-gray: #999;
$scorpion: #7f7d7d;

/******************************
  Blues and Purples
*******************************/

$danube: #6C99C6;
$picton-blue: #328FEC;
$san-marino: #4073A5;
$razzle-dazzle-rose: #EE49E7;
$medium-purple: #A149EE;
$heliotrope: #B35FFC;
$mulled-wine: #4A4A6E;
$deep-cerulean: #006bb5;
$heather: #bcc8d3;
$hoki: #6886A1;
$polo: #6e9ad0;
$steel-blue: #4F81BD;
$waikawa-gray: #5D77A4;
$casper: #AEC1D2;
$pigeon-post: #b5c8e2;
$periwinkle-grey: #CCDBEA;
$havelock-blue: #5885d4;

/******************************
  Reds and Oranges
*******************************/

$rouge: #A5406F;
$burnt-sienna: #EE5149;
$carnation: #F15962;

/******************************
  Standard Colors
*******************************/

$black: #000;
$white: #fff;

/*
#e0e8cc  &   #00798c

#456377   &  #eeddbc*/