@import '../../static/styles/variables';

.big-headings {
  display: flex;
  margin-bottom: 5px;
}
.bh-icon-circle {
  width: $heading-circle-xy;
  height: $heading-circle-xy;
  border-radius: 50%;
  border: $border-lg solid $main-bkg;
  z-index: 2;
  margin-top: -5px;
  margin-left: -5px;
  background-color: $alternate-color;
}
.bh-icon {
  color: $icon-color;
  font-size: $icon-font-size;
  height: $heading-icon-xy;
  width: $heading-icon-xy;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bh-component {
  display: flex;
  background-color: $main-color;
  margin-left: -10px;
  padding-left: 20px;
  margin-top: 3px;
  width: 100%;
  max-height: 32px;
}
.bh-description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  text-transform: uppercase;
  font-weight: $extra-bold;
  display: flex;
  align-items: center;
  font-size: $font-size-lg;
}

.tight-heading {
  margin-bottom: 0px;
}