@import "../../static/styles/variables";
.numbered-headings {
  margin-bottom: 0px;
  margin-top: 0px;
  padding-left: 5px;
  ul {
    list-style: none;
  }
  li {
    display: flex;
    flex-flow: row;
    min-height: 10px;
    position: relative;
  }
  span.number {
    font: $font-size-xl "Gothic A1", sans-serif;
    font-weight: $normal;
    width: 28px;
    height: 28px;
    background: $alternate-color;
    text-align: center;
    border-radius: 50%;
    line-height: 28px;
    box-sizing: content-box;
    color: $white;
    margin-bottom: 30px;
    z-index: 2;
  }
  span.bubbleFont {
    font-size: $font-size-sm;
  }
  span.line {
    position: absolute;
    height: 100%;
    border: solid $footer-border 0.5px;
    left: 12px;
  }
  li:last-child span.line {
    display: none;
  }
  .sidebar-numbered-break {
    margin-left: 10px;
    margin-bottom: 5px;

    .sub-text li {
      color: $tundora !important;
    }
  }

  .sidebar-numbered-break:not(:last-child):after {
    content: "";
    border-bottom: #ccc 1px solid;
    position: absolute;
    left: 7px;
    right: 10px;
    bottom: 0px;
    top: 5px;
   }

  .title-block {
    margin-left: 6px;
    margin-bottom: 6px;
    font-family: "Roboto", sans-serif;
    display: flex;
    flex-direction: column;
    width: 100%;
    .heading {
      display: flex;
      align-items: center;
      line-height: 14px;
      .title {
        text-transform: uppercase;
        font-size: $font-size-sm;
        font-weight: $normal;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
      }
      .title:after {
        content: "\2022";
        color: $alternate-color;
        margin: 0px 8px;
        font-size: 9px;
        display: flex;
        align-items: center;
      }
      .sidebar-title {
        text-transform: uppercase;
        font-size: $font-size-sm;
        font-weight: $normal;
        letter-spacing: 1px;
        display: flex;
        align-items: center;
        color: $tundora;
      }
      .date {
        text-transform: uppercase;
        font-size: $font-size-sm;
        font-weight: $normal;
        color: $dusty-gray;
        display: flex;
        align-items: center;
      }
    }
    .sidebar-date {
        text-transform: uppercase;
        font-size: $font-size-sm;
        font-weight: $normal;
        color: $dusty-gray;
        display: flex;
        align-items: center;
    }
    .sub-item {
      font-family: "Roboto", sans-serif;
      .sub-title {
        font-size: $font-size-xs;
        font-weight: $bold;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-top: 1px;
        .extra-info {
          text-transform: uppercase;
          width: 114px;
          color: $dusty-gray;
          display: flex;
          justify-content: flex-end;
        }
      }
      .sub-title-first-dot {
        justify-content: flex-start;
      }
      .sub-title-text-dot:after {
        content: "\2022";
        color: $alternate-color;
        margin: 0px 3px;
        font-size: 9px;
        align-items: center;
      }
      
      .date {
        text-transform: uppercase;
        color: $dusty-gray;
        font-size: $font-size-xs;
      }
      .sub-title-not-first {
        margin-top: 5px;
      }
      .sub-title-first {
        margin-top: 2px;
      }
      .descriptor {
        color: $scorpion;
        font-size: $font-size-xs;
      }
      .sub-text {
        color: $scorpion;
        font-size: $font-size-tiny;
        margin-right: 2px;
        hyphens: none;
        padding-inline-start: 0px;
        li~li {
          margin-top: 2px;
        }
      }
    }
  }
}