@import "./static/styles/variables";
html {
  display: flex;
  justify-content: center;
}

@page {
  size: letter portrait;
  margin: 0;
}

* {
  box-sizing: border-box;
}

body {
  font-family: "Open Sans", sans-serif;
}

.resume {
  --decorator-horizontal-margin: 0.2in;
  /* XXX: using px for very good precision control */
  --decorator-outer-offset-top: 10px;
  --decorator-outer-offset-left: -5.5px;
  --decorator-border-width: 1px;
  --decorator-outer-dim: 9px;
  --decorator-border: 1px solid #ccc;
  --row-blocks-padding-top: 5pt;
  --date-block-width: 0.6in;
  --main-blocks-title-icon-offset-left: -19pt;
  width: $page-width;
  height: $page-height;
  margin: 0;
  font-weight: 300;
  line-height: 1.3;
  color: #444;
  hyphens: auto;
  background-color: white;
  display: flex;
  flex-direction: column;
}

h1,
h2,
h3 {
  margin: 0;
  color: #000;
}

li {
  list-style-type: none;
}

.content {
  display: flex;
  flex: 1;
  min-height: $content-height;
  .main {
    width: $main-width;
    padding: 15px $main-content-padding 0px $main-content-padding;
    flex: 1;
  }
  .sidebar {
    flex: 0 0 $sidebar-width;
    order: -1;
    background-color: $sidebar-bkg;
    padding: 0.5in 0.13in 0px $sidebar-horizontal-padding;
    min-width: 288px;
    .sidebar-content {
      padding-top: 50px;
      .objective-text {
        font-size: 12px;
        margin-left: 20px;
        margin-bottom: 16px;
        font-weight: $bold;
        hyphens: none;
      }
    }
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}