@import "../../static/styles/variables";
.dot-bar-grouping {
  display: flex;
  flex-direction: column;
  padding-left: 10px;
  .dot-row {
    display: grid;
    grid-template-columns: 170px 170px 150px;
    // display: flex;
    // flex-direction: row;
    // flex-wrap: wrap;
    // justify-content: space-between;
    .dot-bar {
      padding: 3px 0px 0px 0px;
      width: 150px;
      flex: 1 1 120px;
      .dot-title {
        font-family: "Roboto", sans-serif;
        text-transform: uppercase;
        font-size: 8px;
        font-weight: 300;
        letter-spacing: 1px;
      }
      .dots {
        .dot {
          height: 10px;
          width: 10px;
          margin-right: 5px;
          border: 1px solid $alternate-color;
          border-radius: 50%;
          display: inline-block;
          &.filled {
            background: $main-color;
          }
        }
        .legend {
          display: inline-block;
          font-size: 8px;
          vertical-align: middle;
        }
      }
      // &.middle-col {
      //   margin: 0px 0px 0px 40px;
      // }
      // &.last-col {
      //   // width: 120px;
      // }
    }
  }
}