@import './colors';

/***************
  Spacing
***************/

$page-width: 8.5in;
$main-width: 5.75in;
$sidebar-width: calc(#{$page-width} - #{$main-width});


$page-height: 11in;
$footer-height: calc(1in - 4px);
$content-height: calc(#{$page-height} - #{$footer-height});

$border-lg: 4px;
$border-sm: 1px;

$heading-circle-xy: 50px;
$heading-icon-xy: calc(#{$heading-circle-xy} - (2 * #{$border-lg}));


/***************
  Padding
***************/

$sidebar-horizontal-padding: 0.2in;
$main-content-padding: 0.15in;



/***************
  Coloring
***************/

$sidebar-bkg: $alto;
$main-bkg: $white;

$main-color: $pigeon-post;
$alternate-color: $havelock-blue;
$third-color: $polo;

$footer-border: $periwinkle-grey;
$icon-color: $white;



/***************
  Typography
***************/

$link-color: $polo;
$link-hover-color: $main-color;

$normal: 300;
$bold: 400;
$extra-bold: 700;

$font-size-xl: 20px;
$font-size-lg: 18px;
$font-size-md: 14px;
$font-size-sm: 12px;
$font-size-xs: 11px;
$font-size-tiny: 9px;
$icon-font-size: 24px;