.key-certification {
  margin-left: 20px;
  margin-bottom: 10px;
  .cert-heading {
    font-family: "Roboto", sans-serif;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    .cert-image {
      display: flex;
      flex-direction: column;
      padding-right: 10px;
      -webkit-filter: grayscale(100%);
      /* Safari 6.0 - 9.0 */
      filter: grayscale(100%);
      /*opacity: .5;*/
    }
    .key-cert-text {
      display: flex;
      flex-direction: column;
      .key-cert-title {
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 500;
        hyphens: none;
      }

      .key-cert-line {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-top: 3px;

        .key-cert-date {
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 300;
          color: #999;
          &:after {
            content: "\2022";
            color: #5885d4;
            margin: 0px 8px;
          }
        }
        .key-cert-num {
          color: #999;
          font-size: 12px;
        }
      }
      .key-cert-sub-text {
        color: #999;
        font-size: 10px;
      }
    }
  }
}