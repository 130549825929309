@import '../../static/styles/variables';

.home {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;

  .home-content {
    padding-top: 1rem;
    height: calc(100vh - 30px);

    .home-title {
      font-size: 2em;
    }

    .home-sub-title {
      font-weight: $bold;
      padding-top: 20px;
    }

    .site-description, .repo-area {
      a {
        color: $link-color;
        text-decoration: none;
        font-weight: $bold;

        &:hover {
          color: $link-hover-color;
        }
      }
    }

    .yt-wrap {
      width: 100%;
      // margin: 0 auto; // for centering
      max-width: 500px;
      padding: 20px 0px;

      .yt-vid {
        position: relative;
        overflow: hidden;
        height: 0;
        padding-bottom: 56.25%;

        .embed-vid {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          max-width: 100%;
        }
      }
    }

    .tech-stack-area, .contact-area {
      display: flex;
      flex-direction: column;
      margin-top: 30px;
    
      .tech-logo-line, .contact-logo-line {
        display: flex;
        flex-direction: row;
        align-items: center;
        padding: 20px 0px;

        a img {
          max-width: 55px;
          max-height: 55px;
        }

        a ~ a {
          margin: 0px 20px;
        }
      }
    }
  }
}


@media (min-width: 768px) {
  .home {
    width: 750px;
  }
}
@media (min-width: 992px) {
  .home {
    width: $page-width;
  }
}
@media (min-width: 1200px) {
  .home {
    width: $page-width;
  }
}