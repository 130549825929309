@import './static/styles/colors';
@import './static/styles/variables';

.navbar {
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: row;

  li {
    a {
      text-decoration: none;
      color: $black;
      padding: 0px 20px;

      &:hover {
        color: $white;
        background-color: $main-color;
      }
    }
  }

}