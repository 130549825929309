.small-headings {
  display: flex;
  margin-bottom: 5px;
}

.sm-icon-circle {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border: 2px solid #eee;
  z-index: 2;
  margin-top: -5px;
  margin-left: -5px;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sm-icon {
  color: #000;
  margin-left: 1px;
  font-size: 24px;
  text-align: center;
}

.sm-component {
  display: flex;
  background-color: #fff;
  margin-left: -14px;
  padding-left: 20px;
  margin-top: 3px;
  width: 100%;
  max-height: 32px;
  border-bottom: 3px solid #ddd;
  border-right: 2px solid #ddd;
}

.sm-description {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: left;
  min-width: 470px;
}

.sm-text {
  text-transform: uppercase;
  font-size: 15px;
  font-weight: 600;
  display: flex;
  align-items: center;
  line-height: 29px;
}

.adjust-up {
  margin-top: -13px;
  margin-left: -12px;
  position: absolute;
}